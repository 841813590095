export default {
    BASE_URL: "https://api.shanghaicsq.com",
    BASE_WSS: "wss://ws.shanghaicsq.com",
    BASE_IO: "wss://ws.shanghaicsq.com",


    DOMAIN: "https://h5.shanghaicsq.com",
    HELP_URL: "https://help.shanghaicsq.com"
}
// export default {
//     BASE_URL: "https://api.shrahz.com",
//     BASE_WSS: "wss://ws.shrahz.com",
//     BASE_IO: "wss://ws.shrahz.com",
//
//
//     DOMAIN: "https://h5.shrahz.com",
//     HELP_URL: "https://help.shrahz.com"
// }